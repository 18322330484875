@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Safe area insets for notched devices - these are set dynamically by JavaScript */
  --sat: env(safe-area-inset-top, 0px);
  --sar: env(safe-area-inset-right, 0px);
  --sab: env(safe-area-inset-bottom, 0px);
  --sal: env(safe-area-inset-left, 0px);
  
  /* Colors used throughout the app */
  --primary: #3b82f6;
  --primary-dark: #2563eb;
  --primary-light: #60a5fa;
  --success: #10b981;
  --warning: #f59e0b;
  --danger: #ef4444;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  
  /* Dark mode colors with better contrast */
  --dark-base-bg: #121212;
  --dark-elevated-bg: #1e1e1e;
  --dark-high-contrast-bg: #2a2a2a;
  
  /* Animation speeds */
  --transition-fast: 150ms;
  --transition-normal: 300ms;
  --transition-slow: 500ms;
}

/* Dark mode specific variables */
.dark {
  --primary: #60a5fa; /* Lighter blue for dark mode */
  --primary-dark: #3b82f6;
  --primary-light: #93c5fd;
  --success: #34d399; /* Lighter success color for dark mode */
  --warning: #fbbf24; /* Lighter warning color for dark mode */
  --danger: #f87171; /* Lighter danger color for dark mode */
  
  /* Text colors optimized for dark backgrounds */
  --text-primary: #f5f5f5;
  --text-secondary: #d1d5db;
  --text-tertiary: #9ca3af;
  --text-muted: #6b7280;
  
  /* Making sure links are visible on dark backgrounds */
  --link-color: #60a5fa;
  --link-hover-color: #93c5fd;
}

/* Mobile viewport height fix */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain; /* Prevents pull-to-refresh on some browsers */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
}

/* For debugging safe areas - uncomment to see the safe areas visually */
/* .debug-safe-areas {
  --sat-color: rgba(255, 0, 0, 0.2);
  --sar-color: rgba(0, 255, 0, 0.2);
  --sab-color: rgba(0, 0, 255, 0.2);
  --sal-color: rgba(255, 255, 0, 0.2);
  
  &::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--sat);
    background-color: var(--sat-color);
    z-index: 9999;
  }
  
  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: var(--sab);
    background-color: var(--sab-color);
    z-index: 9999;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Safe area utilities */
.pt-safe-top {
  padding-top: var(--sat);
}

.pr-safe-right {
  padding-right: var(--sar);
}

.pb-safe-bottom {
  padding-bottom: var(--sab);
}

.pl-safe-left {
  padding-left: var(--sal);
}

.px-safe {
  padding-left: var(--sal);
  padding-right: var(--sar);
}

.py-safe {
  padding-top: var(--sat);
  padding-bottom: var(--sab);
}

.mt-safe-top {
  margin-top: var(--sat);
}

.mb-safe-bottom {
  margin-bottom: var(--sab);
}

/* Mobile-specific optimizations */
@media (max-width: 640px) {
  /* Larger touch targets on mobile */
  button, 
  [role="button"],
  a {
    min-height: 44px; /* Apple's recommended minimum touch target size */
    min-width: 44px;
  }
  
  /* Fix for 100vh on mobile browsers */
  .h-screen, .min-h-screen {
    height: calc(var(--vh, 1vh) * 100);
  }
  
  /* Prevent text size adjustment on orientation change */
  html {
    -webkit-text-size-adjust: 100%;
  }
}

/* PWA display improvements */
@media (display-mode: standalone) {
  /* Styles for when the app is in standalone mode (installed on home screen) */
  body {
    overscroll-behavior: none; /* Prevent pull-to-refresh in PWA mode */
  }
  
  /* Hide browser UI elements that might still be visible */
  .browser-ui-hide {
    display: none !important;
  }
}

/* Animation utilities */
.animate-fade-in {
  animation: fadeIn var(--transition-normal) ease-in-out forwards;
}

.animate-fade-out {
  animation: fadeOut var(--transition-normal) ease-in-out forwards;
}

.animate-slide-up {
  animation: slideUp var(--transition-normal) ease-in-out forwards;
}

.animate-slide-down {
  animation: slideDown var(--transition-normal) ease-in-out forwards;
}

/* Keyframes for animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  /* Fix for iOS Safari 100vh issue */
  .h-screen {
    height: -webkit-fill-available;
  }
  
  /* Fix for momentum scrolling */
  .momentum-scroll {
    -webkit-overflow-scrolling: touch;
  }
  
  /* Fix for position:fixed elements disappearing during scrolling */
  .fix-ios-fixed {
    transform: translateZ(0);
  }
}

/* Better touch interactions */
button, [role="button"], a, select, input {
  touch-action: manipulation; /* Optimizes for touch */
}

/* Fix for flickering shadows on iOS */
.ios-shadow-fix {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  transform: translateZ(0);
}
